<template>
  <v-form>
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          v-model="form.realName"
          :label="$vuetify.lang.t('$vuetify.user.realName')"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          class="mx-2 float-right"
          color="primary"
          fab
          small
          @click="handleSearch"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>

    </v-row>
  </v-form>
</template>

<script>

  export default {
    data() {
      return {
        form: {
          realName: null
        }
      }
    },

    created () {
    },

    methods: {

      handleSearch() {
        const data = {}
        for (let k in this.form) {
          if (this.form[k]) {
            data[k] = this.form[k]
          }
        }
        this.$emit('submit', data)
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
